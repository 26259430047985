<template>
  <v-app>
    <v-overlay :value="banner == true && device == 'Mobile'">
    <Banner/>
    </v-overlay>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-main class="NoneTransition" :class="$vuetify.theme.dark ? 'black' : 'grey lighten-4'" v-show="banner == false">
      <NotifApp/>
      <Appbar v-if="this.logged != null"/>
      <BottomMobile class="float" v-if="this.logged != null && this.device == 'Mobile'"/>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
  import Vue from 'vue'
  import Vuetify from 'vuetify/lib'
  import firebase from '@/services/firebase-sw.js'

  Vue.use(Vuetify)
  const Banner = () => import (/* webpackChunkName: "Banner"*/ '@/components/Banner.vue')
  const NotifApp = () => import (/* webpackChunkName: "NotifApp"*/ '@/components/NotifApp.vue')
  const Appbar = () => import (/* webpackChunkName: "AppBar"*/ '@/components/Appbar.vue')
  const BottomMobile = () => import (/* webpackChunkName: "BottomMobile"*/ '@/components/BottomMobile.vue')
  export default {
    components: {
      Banner,Appbar,NotifApp,BottomMobile
    },

    data () {
      return {
        device:null,
        overlay:false,
        banner:true,
        notifToken:null,
        logged:JSON.parse(localStorage.getItem('logged')),
        tokenlist:[],
        TokenIT:[],
        Stored:null,
      }  
    },
    created(){
      console.log('ee',this.logged)
      this.UserCek()
      // console.log(fbnotif.messaging())
    },
    beforeCreate(){
    //  Vue.prototype.ShowAppBar = false
    },
    mounted(){
      // console.log('rsat',window.devicePixelRatio)
      if(window.location.pathname == '/auth'){
        this.showappbar = false
      }else{
        this.showappbar = true
      }
      this.cekuser()
      let cek = navigator.userAgent.split(/\s*[;)(]\s*/);
      if(cek[1] == "Linux" || cek[1] == "iPhone" || cek[1] == "iPad"){
        this.device = "Mobile"
      }else{
        this.device = "Desktop"
      }
      if(this.device == 'Desktop'){
        this.banner = false
      }
      Vue.prototype.$cekuser = this.cekuser
      Vue.prototype.$loading = this.loading
      Vue.prototype.$UserLogged = this.UserLogged
      Vue.prototype.$showAppBar = this.showAppBar
      Vue.prototype.$SetLog = this.SetLog
      Vue.prototype.$device = this.device
      Vue.prototype.$ConnectFirebase = this.ConnectFirebase
      Vue.prototype.$Toast = this.Toast
      Vue.prototype.$ChangeURL = this.ChangeURL
      Vue.prototype.$Store = this.Store
      Vue.prototype.$CekStore = this.CekStore
      Vue.prototype.$DateConvert = this.dateconvert
      Vue.prototype.$HideBanner = this.HideBanner
      // console.log(self)
    },
    methods:{
      UserCek(){
        console.log('user cek')
        let u = JSON.parse(localStorage.getItem('logged'))
          if(u != null){
            this.ConnectFirebase()
            this.ITNotif()
            console.log('user login')
        }
        else{
          // this.DisconectFirebase()
          console.log('user masi kosong')
        }
      },
      HideBanner(){
        console.log('baner false')
        this.banner = false
      },
      showAppBar(){
        this.logged = JSON.parse(localStorage.getItem('logged'))
      },
      cekuser(){
        let user = JSON.parse(localStorage.getItem('logged'))
        // firebase.database().ref('userlevel/'+user.level+'/'+user.id_user).onDisconnect().set(null);
        if(!user){
          if(this.$router.currentRoute.name != "Login"){
            this.$router.push('/auth')
          }
        }
      },
      ConnectFirebase(){
        console.log('conected')
        let directsx = localStorage.getItem('direct')
        if(directsx){
          var adaRef = firebase.database().ref(directsx);
          adaRef.remove()
          adaRef.off()
          localStorage.removeItem('direct')
        }
        let u = JSON.parse(localStorage.getItem('logged'))
        let db = firebase.database()
        try {
          firebase
            .messaging()
            .requestPermission()
            .then(() => {
              console.log("Notification permission granted");
              firebase
                .messaging()
                .getToken()
                .then((token) => {
                  // window.console.log("token ", token);
                  this.notifToken = token
                  let direct = 'userlevel/'+u.level+'/'+u.id_user
                  // let directs = 'userlevel/'+u.level
                  // var adaRef = db.ref(directs);
                  // adaRef.remove()
                  // adaRef.off()

                  // const eventref = db.ref(direct) 
                  // let snapshot = eventref.once('value');
                  // var ref = firebase.database().ref(direct);
                  // ref.update({
                  //   onlineState: true,
                  //   status: "I'm online."
                  // });
                  db.ref(direct).on('value', snapshot => {
                    let data = snapshot.val();
                    console.log('dat',data)
                    let messages = [];
                    if(data != null){
                      Object.keys(data).forEach(key => {
                      messages.push(data[key]);
                      });
                      console.log('tes',messages)
                      if(messages.includes(token) == false){
                        console.log('false')
                        db.ref(direct).push(token)
                      }
                    }else{
                      db.ref(direct).push(token)
                    }
                  });
                  // db.ref(direct).off("value")
                  this.receiveMessage();
                });
            })
            .catch((err) => {
              console.log("Unable to get token ", err);
            });
          } catch (e) {
            console.log('firebase',e);
          }
      },
      async ITNotif(){
        // const eventrefIT = firebase.database().ref("userlevel/3"); //IT
        // let snapshotIT = await eventrefIT.once('value');
        // let dx = snapshotIT.val();
        // Object.keys(dx).forEach(key => {
        //   this.TokenIT.push(dx[key]);
        // });
        Vue.prototype.$TokenIT = this.TokenIT
      },
      loading(v){
        this.overlay = v
      },
      Store(x){
        this.Stored = x
      },
      CekStore(){
        return this.Stored
      },
      SetLog(){
        return JSON.parse(localStorage.getItem('logged'))
      },
      receiveMessage() {
        firebase.messaging().onMessage((payload) => {
          console.log("payload ", payload);
          var myAudio = new Audio(window.location.origin+'/storage/SuaraNotif.mpeg');
          myAudio.play();
          // console.log('NOTIF WORK')
          navigator.serviceWorker.ready.then(function(registration) {
                registration.showNotification(payload.notification.title, {
                  body: payload.notification.body,
                  data: payload.notification.isi,
                  icon: payload.notification.icon,
                  badge:payload.notification.icon,
                });
              });
          this.$Notifkan(payload.notification)
        });
    },
      Toast(icon,title){
          this.$swal.fire({
          toast: true,
          icon: icon,
          title: title,
          animation: false,
          showCloseButton: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true})
      },
      UserLogged(){
        return JSON.parse(localStorage.getItem('logged'))
      },
      ChangeURL(x){
        if(this.$route.path == '/'+x){
            return
        }
        if(x == 'auth'){
          return this.LogOut()
        }else{
          return this.$router.push('/'+x)
        }
      },
      dateconvert(x){
       let date = new Date(x)
       let monthyear = date.toLocaleString('en-us',{month:'short', year:'numeric'})
       let tgl = date.getDate()
       return tgl+' '+monthyear
      },
    },
    watch:{
      $route(){
        this.rute = this.$route.name
        // let check = this.ruteblock.includes(this.$route.name)
        // if(check == true){
        //   this.isblock = false
        // }else{
        //   this.isblock = true
        // }
      }
    },
    
  };
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400&subset=latin,latin-ext);
/* html::-webkit-scrollbar {
  display:none
} */
  .ScrollCSSHide::-webkit-scrollbar {
    display: none;
  }
/* html {   max-height: 100% !important; }  */
  .toolbar-text{
  color:#a10115;
  }
  .float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	color:#FFF;
    z-index: 10;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}
</style>
